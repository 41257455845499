import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Prevent POST requests to certain files or paths
    if (this.shouldBlockRequest(req)) {
      return throwError(
        () => new Error(`POST requests to ${req.url} are not allowed`)
      );
    }
    return next.handle(req).pipe(catchError(this.handleError));
  }

  private shouldBlockRequest(req: HttpRequest<any>): boolean {
    const condition =
      req.method === "POST" &&
      (req.url.includes("/robots.txt") || !!req.url.match(/\.js$/));

    return condition;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error("Request error", error);

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      return throwError(() => new Error(`Client-side error: ${error.error.message}`));
    } else {
      // Server-side error
      return throwError(() => new Error(`Server error: ${error.status} - ${error.message}`));
    }
  }
}
