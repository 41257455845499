import { Component, Input, OnInit, AfterViewInit } from "@angular/core";
import {
  ApexChart,
  ApexTitleSubtitle,
  ApexXAxis,
  ChartType,
} from "ng-apexcharts"; // Make sure you have this library installed

interface ChartOptions {
  series: { name: string; data: number[] }[];
  chart: ApexChart;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
}

@Component({
  selector: "app-barchart",
  templateUrl: "./barchart.component.html",
  styleUrls: ["./barchart.component.scss"],
})
export class BarchartComponent implements OnInit, AfterViewInit {
  @Input() customOption: string[] = [];
  @Input() customOptionVal: number[] = [];

  public chartOptions: ChartOptions;

  ngOnInit(): void {
    this.chartOptions = this.getChartOptions(
      this.customOption,
      this.customOptionVal
    );
  }

  private getChartOptions(categories: string[], data: number[]): ChartOptions {
    return {
      series: [
        {
          name: "Survey Opinion",
          data: data,
        },
      ],
      chart: {
        height: 350,
        type: "bar" as ChartType, // Ensure that 'type' is a ChartType
      },
      title: {
        text: "Survey Opinion",
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: categories,
        tickPlacement: "on",
      },
    };
  }

  ngAfterViewInit(): void {
    console.log("View initialized.");
  }
}
