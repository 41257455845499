import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BarchartComponent } from "./user/barchart/barchart.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { GlobalErrorHandler } from './global-error-handler';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgbModule, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { SecurityInterceptor } from "./security.interceptor";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [AppComponent, BarchartComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NgApexchartsModule,
    NgbModule,
    NgbTypeahead,
    NgxMaskDirective,
    NgxMaskPipe,
    ToastrModule.forRoot(),
  ],
  providers: [
    provideNgxMask(),
    { provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
